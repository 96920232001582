table.is-sticky thead tr th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;  
}

#reasons td:nth-child(1) {
  width: 25%;
}

#reasons td:nth-child(2) {
  width: 10%;
}

#reasons td:nth-child(4) {
  width: 25%;
}